// global styles
body {
    font-family: $font-family-base;
    color: $black-10;
    background-color: $snow-12;
}

//headings
h2,h3,h4,h5,h6{
    color: $black-12;
}

.font-family-poppins{
    font-family: $font-family-poppins !important;
}

.text-decoration-none{
    text-decoration: none !important;
}

.text-center{
    text-align: center !important;
}

.text-danger{
    color: $danger !important;
}

.flex-wrap{
    flex-wrap: wrap !important;
}

.font-size-lg{
    font-size: $font-size-lg !important;
}

.font-size-xl{
    font-size: $font-size-xl !important;
}

.font-size-base{
    font-size: $font-size-base !important;
}

.font-size-15{
    font-size: 0.9375rem !important;
}

.font-size-sm{
    font-size: $font-size-sm !important;
}

.font-size-xs{
    font-size: $font-size-xs !important;
}

.font-weight-medium{
    font-weight: $font-weight-medium !important;
}

.font-weight-semi-bold{
    font-weight: $font-weight-semi-bold !important;
}

.font-weight-normal{
    font-weight: $font-weight-normal !important;
}

.font-weight-bold{
    font-weight: $font-weight-bold !important;
}

.warning-wrap{
    position: relative;
    border-radius: .375rem;
    background-color: $orange-10;
    padding: .5rem .75rem .5rem 2.5rem;
    color: $black-10;
    border: .0625rem dashed $yellow-11;
    .icon-left{
        position: absolute;
        left: .75rem;
        top: .375rem;
    }
}

//
.main-content{
    padding: 5.125rem 0 0 0;
}

.page-spacing-bottom{
    padding-bottom: 1.5rem;
}

.full-page{
    overflow: auto;
}

.panel-md-50{
    width: 100%;
}

.question-panel{
    // max-width: 584px;
    width: 100%;
}

.test-title-wrap {
    width: calc(100% - 60px);

    p {
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
    }
}

.muiSelectControl {
    background-color: $white;
    border-radius: .375rem !important;
    border-color: $gray-300 !important;
    .MuiSelect-select {
      min-height: auto !important;
      padding: .3125rem .75rem;
      border-radius: .375rem;
      .MuiTypography-root {
        display: flex;
        align-items: center;
      }
    }
    .MuiOutlinedInput-notchedOutline {
      border-color: $gray-300 !important;
    }
    &.Mui-focused {
        .MuiOutlinedInput-notchedOutline {
            border-width: .0625rem !important;
            border-color: $primary !important;
        }
    }
    &.Mui-error {
        .MuiOutlinedInput-notchedOutline {
            border-width: .0625rem !important;
            border-color: $danger !important;
        }
    }
    .MuiSelect-icon {
      padding: 0;
      color: $gray-500;
      font-size: $font-size-base;
      top: 50%;
      transform: translateY(-50%);
    }
    &:hover {
      .MuiOutlinedInput-notchedOutline {
        border-color: $gray-300 !important;
      }
    }
    &.muiSelectCameraMicWrap {
        background-color: transparent;
        border-radius: 0 !important;
        border: none !important;
        .MuiOutlinedInput-notchedOutline {
            border-color: transparent !important;
        }
        .MuiSelect-select {
            min-height: 30px !important;
            display: flex;
            font-size: .875rem;
            line-height: 2.2;
            .MuiTypography-root {
                font-size: .875rem;
                line-height: 2.2;
                display: block;
                white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
            }
        }
        &:hover {
            .MuiOutlinedInput-notchedOutline {
                border-color: transparent !important;
            }
        }
    }
}

.slick-track { display: flex !important; }
.slick-slide { height: inherit !important; }
.slick-slide > div { height: 100% !important; }
.slick-arrow { color: #6059D4; }
.slick-arrow:hover { color: #6059D4; }

@media (min-width: 768px) {
    .panel-md-50{
        width: 50%;
    }
    .main-content{
        .full-page{
            height: calc(100vh - 5.125rem);
        }
        .has-warning{
            .full-page{
                height: calc(100vh - 134px);
            }
        }
    }
}

.wow-wrapper {
    padding-top: 113px;
    padding-bottom: 60px;
    height: 100vh;
    background-image: url('../images/bg2.svg');
    background-size: cover;
    background-repeat: no-repeat;
}
.wow-card {
    .wow-card-left {
        width: 145px;
        img {
            object-fit: cover;
            width: 100%;
            height: 100%;
        }
    }
    .wow-card-right {
        width: calc(100% - 145px);
        padding-left: 10px;
        h3 {
            color: $gray-900;
            font-size: $font-size-xxl;
            font-weight: $font-weight-bold;
        }
        p {
            color: $gray-800;
            font-size: $font-size-base;
        }
    }
}
.wow-footer {
    position: absolute;
    bottom: 30px;
    left: 0;
    width: 100%;
}

@media(max-width: 1199px) {
    .timer__content {
        padding-left: 8px;
        padding-right: 8px;
    }
    .timer__num {
        font-size: 12px;
    }
}

@media(max-width: 991px) {
    .camera-mic-setup {
        padding: 30px !important;
    }
    .webcam-record-video {
        video {
            width: 100%;
            height: 100%;
            object-fit: cover;
        }
    }
    .testing-tops, .testing-bottoms, .few-thing-card {
        padding: 30px !important;
    }
}

@media(max-width: 767px) {
    .congratulations-cards {
        .wow-card-right {
            width: 100%;
            padding-top: 30px;
        }
    }
    .camera-mic-setup {
        padding: 20px !important;
        h2 {
            font-size: 1.25rem;
            img {
                margin-right: 10px !important;
            }
        }
    }       
    .webcam-record-video .video-record {
        height: 200px;
    }
    .testing-tops, .testing-bottoms {
        padding: 20px !important;
        h3 {
            font-size: 1.125rem;
        }
    }    

    .few-thing-card {
        padding: 20px !important;
        h2 {
            font-size: 1.5rem;
            line-height: 1.2;
        }
    }
    .welcome-top-h1 {
        h1 {
            font-size: 2rem;
        }
    }
    .test-left-pannels {
        padding: 20px !important;
    }
    .test-right-pannels {
        padding: 30px 20px 50px !important;
    }
}