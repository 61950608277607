.multiple-choice-list{
  li{
    padding: 1.2rem 1.25rem;
    background-color: $white;
    border:.0625rem solid $gray-300;
    border-radius: .375rem;
    margin-bottom: 1.25rem;
  }
}


.multiple-choice-Test-list{
  li{
    background-color: $white;
    border:.0625rem solid $gray-300;
    border-radius: .375rem;
    margin-bottom: 1.25rem;
    overflow: hidden;
  }
}