.notification-banner{
  position: absolute;
  left: 0;
  right: 0;
  &.warning{
    background-color: $yellow-13;
  }
  // &.notify-static {
  //   position: static;
  // }
}