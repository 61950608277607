// color
$black: #000;
$white: #fff;
$dark: #212121;
$secondary: #B5B5C3;
$success: #29CC97;
$danger: #F64E60;
$primary: #7A1AF0;
$warning: #FFAC2F;

$black-10: #3F4254;
$black-11: #5E6278;
$black-12: #181C32;
$black-13: #313042;
$black-14: #9099A3;
$black-15: #434254;
$black-16: #1f1e2e;
$black-17: #80808F;
$black-18: #333B68;
$black-19: #252C51;

$green-11: #1BC5BD;
$green-12: #10B759;
$green-13: #0BB783;
$green-14: #D7F9EF;

$blue-11: #8950FC;
$blue-12: #009EF6;
$blue-13: #EEE5FF;
$blue-14: #3699FF;
$blue-15: #403E5D;
$blue-16: #F1FAFF;
$blue-17: #006CBA;

$snow-11: #E7ECF0;
$snow-12: #F3F6F9;
$snow-13: #F9FAFB;
$snow-14: #C2CBD1;

$orange-10: #FFF7E7;

$gray-11: #B5B5C3;
$gray-12: #E4E6EF;
$gray-13: #7E8299;
$gray-14: #F5F8FA;
$gray-15: #D8DFE3;
$gray-16: #E5EAEE;
$gray-17: #DCDEEC;
$gray-18: #D9D9D9;
$gray-19:  #EFF2F5;

$yellow-11: #FFA800;
$yellow-12: #FFF4DE;
$yellow-13: #FFE9BE;

$red-11: #FFE2E5;

//New Variables
$purple-50: #fbeeff;
$purple-100: #f8e6ff;
$purple-150: #dfd3fc;
$purple-200: #f1ccff;
$purple-300: #d359fe;
$purple-400: #be50e5;
$purple-500: #a947cb;
$purple-600: #9e43bf;
$purple-700: #7f3598;
$purple-800: #5f2872;
$purple-900: #4a1f59;

$gray-50: #f2f2f2;
$gray-100: #ececec;
$gray-200: #d7d7d7;
$gray-300: #7e7e7e;
$gray-400: #adadad;
$gray-500: #717171;
$gray-600: #656565;
$gray-700: #5f5f5f;
$gray-800: #4c4c4c;
$gray-900: #393939;



// font family
$poppins: 'Poppins';
$roboto: 'Roboto';
$MulishRegular: 'MulishRegular';
$MulishMedium: 'MulishMedium';
$MulishSemiBold: 'MulishSemiBold';
$font-family-base: $roboto;
$font-family-roboto: $roboto;
$font-family-poppins: $poppins;

//font-size
$font-size-xs: .75rem;
$font-size-xmd: .812rem;
$font-size-sm: .875rem;
$font-size-base: 1rem;
$font-size-lg: 1.125rem;
$font-size-xl: 1.25rem;
$font-size-xxl: 1.5rem;

//font-weight
$font-weight-normal: 400 !default;
$font-weight-medium: 500 !default;
$font-weight-semi-bold: 600 !default;
$font-weight-bold: 700 !default;

$path-to-fonts: '../../fonts' !default;